/*export const emailConfig = {
    serviceId: 'service_unbce8d',
    publicKey: 'vbpXCpvI5nr6MfOpf',
    templateForm: 'template_99swgwu',
    templateWorkForUs: 'template_zoax80e',
}
*/
export const emailConfig = {
    serviceId: 'service_p9lcdzg',
    publicKey: 'sdwxwapH6NCC5rscK',
    templateForm: 'template_wn0ai5k',
    templateWorkForUs: 'template_sa01dcr',
}
