import React, { useEffect } from 'react';

const LinkedinPosts = () => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://widgets.sociablekit.com/linkedin-page-posts/widget.js';
        script.async = true;
        script.defer = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return (
        <div>
            <div
                className='sk-ww-linkedin-page-post'
                data-embed-id='25430774'

            >
            </div>
        </div>

    );
};

export default LinkedinPosts;