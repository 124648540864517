import React from 'react';
import './CookieBanner.scss';
import { useCookies } from 'react-cookie';

const CookieBanner = () => {
    const [cookies, setCookie] = useCookies(['cookieAccepted']);

    const acceptCookie = () => {
        setCookie('cookieAccepted', true, { path: '/' });
    };

    if (!cookies.cookieAccepted) {
        return (
            <div className="cookie-banner">
                <p>
                    Il nostro sito utilizza i cookie per migliorare l'esperienza dell'utente. Accettando, acconsenti all'uso dei cookie.
                    Puoi visitare <a href="/privacy-policy" target='_blank'>qui</a> le nostre privacy policy.
                </p>
                <button onClick={acceptCookie}>Accetta</button>
            </div>

        );
    } else {
        return null;
    }
};

export default CookieBanner;
