import * as React from 'react';
import HomeTopic from "../components/HomeTopic/HomeTopic";
import HeroHome from "../components/HeroHome";
import { Container, Stack, Image } from "@chakra-ui/react";
import HomeMiddleSection from "../components/HomeMiddleSection/HomeMiddleSection";
import LinkedinPosts from "../components/LinkedinPosts/LinkedinPosts";
import CookieBanner from "../components/CookieBanner/CookieBanner";
import VideoSection from "../components/VideoSection/VideoSection";
import logo from '../asset/loghi/logo_scritta_bianca_trasparente.png';
import {
    homeCards,
    middleSectionLinks,
    middleSectionText,
} from "../Content";

function Home() {
    return (
        <div className={'home--page'}>

            <HeroHome>

            </HeroHome>
            <Image
                width={'30%'}
                maxWidth={'10rem'}
                margin={'.5rem auto'}
                marginBottom={'400px'}
                marginTop={'-400px'}
                src={logo}
                alt="Divitech Logo"
                transform={"scale(3)"}
                draggable={false}
            />
            <Container
                maxW='7xl'
                mt={20}
                mb={'7rem'}

            >
                <Stack
                    flexDirection={{
                        base: 'column',
                        md: 'row',
                    }}

                    alignItems={'center'}
                >
                    {
                        homeCards.map((topic, index) => {
                            return (
                                <HomeTopic
                                    key={index}
                                    icon={topic.icon}
                                    title={topic.title}
                                    content={topic.content}
                                    showImgLogo={index === 1}
                                />
                            )
                        })
                    }
                </Stack>
            </Container>
            <HomeMiddleSection
                text={middleSectionText}
                links={middleSectionLinks}
            />
            <VideoSection />
            <LinkedinPosts />
            <CookieBanner />
        </div>
    )
}

Home.propTypes = {}

Home.defaultProps = {}

export default Home;
