import * as React from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Flex,
    Heading,
    Container,
    Text,
    Button,
    Stack,
    Image,
    createIcon,
} from '@chakra-ui/react';
import bgImage from "../../asset/immagini/programmatore.jpg";
function PagesHero(
    {
        bgImage,
    }
) {
    return (
        <Flex
            w={'full'}
            h={'68vh'}
            backgroundImage={
                bgImage
            }
            py={20}
            backgroundSize={'cover'}
            backgroundPosition={'center center'}
            position={'relative'}
        >
        </Flex>
    )
}

PagesHero.propTypes = {}

PagesHero.defaultProps = {}

export default PagesHero;
