import * as React from 'react';
import "./PrivacyPolicy.scss"

import immagineHomepage from '../../asset/immagine_homepage.jpg';
import LogoDivitech from '../../asset/loghi/logo_scritta_bianca_trasparente.png';

function PrivacyPolicy() {
    return (
        <div className='privacyContainer'>
            <div
                className='privacyMenu'
                style={{ backgroundImage: `url(${immagineHomepage})` }}
            >
                <div className="elementor-shape elementor-shape-bottom" data-negative="true">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none">
                        <path className="elementor-shape-fill"
                            d="M500,97C126.7,96.3,0.8,19.8,0,0v100l1000,0V1C1000,19.4,873.3,97.8,500,97z" />
                    </svg>
                </div>
                <div className='logoDivitech' onClick={() => window.location.href = "/"}>
                    <img src={LogoDivitech}/>
                </div>
                
            </div>
            <div className='privacyText'>
                <p>
                    <strong>Che cosa sono i cookie?</strong><br />
                    I cookie sono piccoli file di testo che vengono memorizzati sul tuo dispositivo (computer, tablet, smartphone) quando visiti un sito web. Sono utilizzati per migliorare l'esperienza di navigazione dell'utente, ad esempio ricordando le preferenze o le sessioni di login.
                </p>
                <p>
                    <strong>Tipi di cookie utilizzati</strong><br />
                    Il nostro sito utilizza esclusivamente cookie tecnici. Questi cookie sono essenziali per il corretto funzionamento del sito e non richiedono il consenso dell'utente. In particolare, utilizziamo:
                    <ol>
                        <li><strong>Cookie di navigazione o di sessione:</strong> Garantiscono la normale navigazione e fruizione del sito web. Questi cookie sono necessari per permettere l'uso di determinate funzionalità e servizi offerti sul nostro sito.</li>
                        <li><strong>Cookie funzionali:</strong> Memorizzano le scelte effettuate dall'utente (come la lingua, o le preferenze sui cookies) per migliorare e personalizzare l'esperienza di navigazione.</li>
                    </ol>
                </p>
                <p>
                    <strong>Finalità dell'uso dei cookie tecnici</strong><br />
                    L'uso dei cookie tecnici ha le seguenti finalità:
                    <ul>
                        <li>Consentire la navigazione e la fruizione del sito web.</li>
                        <li>Memorizzare le preferenze di navigazione.</li>
                        <li>Assicurare la sicurezza del sito e prevenire attività fraudolente.</li>
                        <li>Facilitare l'accesso a determinate aree del sito.</li>
                    </ul>
                </p>
                <p>
                    <strong>Base giuridica per l'uso dei cookie tecnici</strong><br />
                    In conformità con la normativa vigente (Regolamento UE 2016/679 - GDPR e D.Lgs. 196/2003 così come modificato dal D.Lgs. 101/2018), l'uso di cookie tecnici non richiede il consenso preventivo dell'utente. Tuttavia, è obbligatorio fornire un'informativa chiara e dettagliata sull'uso di tali cookie.
                </p>
                <p>
                    <strong>Gestione dei cookie</strong><br />
                    La maggior parte dei browser accetta automaticamente i cookie. Tuttavia, puoi scegliere di non accettare i cookie modificando le impostazioni del tuo browser. Ti ricordiamo che disabilitare i cookie tecnici potrebbe compromettere il funzionamento del sito e limitarne l'accesso.
                </p>
                <p>
                    <strong>Contatti</strong><br />
                    Per qualsiasi domanda o richiesta relativa alla presente informativa sui cookie, puoi contattarci ai seguenti recapiti:
                    <ul>
                        <li>Email: info@divitech.it</li>
                        <li>Telefono: +39 011 9981611</li>
                    </ul>
                </p>
                <p><strong>Data di ultimo aggiornamento:</strong> 12/06/2024</p>
            </div>

            <div className='footer'>
                <div>
                    <img src={LogoDivitech} className='logoDivitechFooter' />
                </div>
                
            </div>
        </div>

    );
}

export default PrivacyPolicy;
