import React, { useState, useEffect, useRef } from 'react';
import { Box } from '@chakra-ui/react';
import ReactPlayer from 'react-player';

const videos = [
    {
        src: '/Videos/ArgoWaste.mp4',
        cover: '/Cover/ArgoWaste.jpg',
        description: "ArgoWaste - soluzione completa per l'igiene urbana"
    },
    {
        src: '/Videos/supplyChain.mp4',
        cover: '/Cover/supplyChain.jpg',
        description: ""
    },
    {
        src: '/Videos/TrasportoPubblico.mp4',
        cover: '/Cover/TrasportoPubblico.jpg',
        description: "ArgoTPL - suite di applicativi per il traporto pubblico"
    },
    {
        src: '/Videos/VideoHelpme.mp4',
        cover: '/Cover/VideoHelpme.jpg',
        description: "HelpMe+ - per la sicurezza del lavoratore isolato"
    },
    {
        src: '/Videos/Video_Polizia_Stradale_TopCrash.mp4',
        cover: '/Cover/Video_Polizia_Stradale_TopCrash.jpg',
        description: "Topcrash - per il rilievo digitale dei sinistri"
    },
];

function VideoSection() {
    const [selectedVideo, setSelectedVideo] = useState(videos[0].src);
    const [startPlay, setStartPlay] = useState(false);
    const [isDragging, setIsDragging] = useState(false);
    const [dragStartX, setDragStartX] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);
    const scrollContainerRef = useRef(null);

    const handleVideoChange = (videoSrc) => {
        setSelectedVideo(videoSrc);
        setStartPlay(true);
    };

    useEffect(() => {
        const handleScroll = () => {
            const videoSection = document.getElementById('video-section');
            if (videoSection && !startPlay) {
                const rect = videoSection.getBoundingClientRect();
                const isVisible = rect.top >= 0;
                if (isVisible) {
                    setStartPlay(true);
                }
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [startPlay]);

    const handleMouseDown = (event) => {
        setIsDragging(true);
        setDragStartX(event.clientX);
        setScrollLeft(scrollContainerRef.current.scrollLeft);
    };

    const handleMouseMove = (event) => {
        if (!isDragging) return;
        const delta = event.clientX - dragStartX;
        scrollContainerRef.current.scrollLeft = scrollLeft - delta;
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };

    return (
        <Box
            id="video-section"
            width="80%"
            maxWidth="1000px"
            position="relative"
            left="50%"
            transform="translateX(-50%)"
            alignItems="center"
            justifyContent="center"
            //marginBottom="-100px"
            style={{ overflowX: 'hidden' }}
            marginBottom={'100px'}
            marginTop={'100px'}
        >
            {/* Video selezionato*/}
            <ReactPlayer
                id="selected-video"
                marginLeft={{
                    base: '0',
                    md: '-80px',
                    xl: '-120px',
                }}
                width={{
                    base: '100%',
                    md: '100%',
                }}
                style={{ 
                    borderRadius: '8px',
                    overflow: 'hidden',
                }}
                position="absolute"
                height="auto"
                controls
                url={selectedVideo}
                playing={startPlay}
                muted={true}
            >
                Your browser does not support the video tag.
            </ReactPlayer>

            {/* Playlist */}
            <Box
                ref={scrollContainerRef}
                marginTop="10px"
                height="140px"
                width="100%"
                overflowX="scroll"
                overflowY="hidden"
                whiteSpace="nowrap"
                style={{ WebkitOverflowScrolling: 'touch' }}
                onMouseDown={handleMouseDown}
                onMouseMove={handleMouseMove}
                onMouseUp={handleMouseUp}
                onMouseLeave={handleMouseUp}
            >
                {videos.map((video, index) => (
                    <Box
                        key={index}
                        as="button"
                        onClick={() => handleVideoChange(video.src)}
                        cursor="pointer"
                        marginRight="5px"
                        boxShadow="0px 2px 5px -1px gray"
                        width="200px"
                        style={{ 
                            borderRadius: '8px',
                            display: 'inline-block',
                            userSelect: 'none',
                        }}
                    >
                        {/* Copertina video nella playlist */}
                        <img
                            src={video.cover}
                            width="200px"
                            height="auto"
                            draggable="false"
                            style={{ 
                                borderRadius: '8px',
                                userSelect: 'none',
                             }}
                            muted
                            playsInline
                        />
                    </Box>
                ))}
            </Box>
        </Box>
    );
}

export default VideoSection;
