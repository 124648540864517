import * as React from 'react';
import "./QualityPolicy.scss"

import immagineHomepage from '../../asset/immagine_homepage.jpg';
import LogoDivitech from '../../asset/loghi/logo_scritta_bianca_trasparente.png';

function QualityPolicy() {
    return (
        <div className='qualityContainer'>
            <div
                className='qualityMenu'
                style={{ backgroundImage: `url(${immagineHomepage})` }}
            >
                <div className="elementor-shape elementor-shape-bottom" data-negative="true">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none">
                        <path className="elementor-shape-fill"
                            d="M500,97C126.7,96.3,0.8,19.8,0,0v100l1000,0V1C1000,19.4,873.3,97.8,500,97z" />
                    </svg>
                </div>
                <div className='logoDivitech' onClick={() => window.location.href = "/"}>
                    <img src={LogoDivitech} />
                </div>

            </div>
            <div className='qualityText'>
                <p style={{ fontSize: '18px' }}>
                    Divitech persegue alti livelli di qualità continuando a migliorare le proprie prestazioni ottimizzandone la gestione, in modo da poter garantire soluzioni personalizzate e performanti, in linea con le esigenze dei nostri clienti. <br /><br />
                    In particolare, Divitech investe nel mantenimento e nel perseguimento dei seguenti principi:
                </p>
                <p>
                    <ul>
                        <li>Adottare una corretta <strong>Cultura del Cliente</strong>, garantendo: <br /></li>
                        <br />
                        <ol>
                            <li>Disponibilità</li>
                            <li>Versatilità</li>
                            <li>Affidabilità</li>
                            <li>Professionalità</li>
                            <li>Attenzione</li>
                            <li>Profonda conoscenza del contesto</li>
                        </ol>
                    </ul>
                </p>
                <p>
                    <ul>
                        <li>Garantire alte prestazioni, attraverso una <strong>Cultura del Prodotto </strong>basata su: <br /></li>
                        <br />
                        <ol>
                            <li>Ottimi risultati</li>
                            <li>L'utilizzo di tecnologia complessa</li>
                            <li>L'obiettivo di mantenerci tra i leader del settore</li>
                            <li>La giusta fidelizzazione</li>
                            <li>Comprovata qualità</li>
                            <li>Eccellente post-assistenza</li>
                        </ol>
                    </ul>
                </p>
                <p>
                    <ul>
                        <li>Ricercare le strategie per assicurarci il miglior <strong>Ambiente di lavoro</strong> e le migliori <strong>Persone</strong>, garantendo:<br /></li>
                        <br />
                        <ol>
                            <li>Libertà di espressione</li>
                            <li>Contesto di lavoro flessibile</li>
                            <li>Stile informale e sobrio</li>
                            <li>Alto livello tecnico e culturale</li>
                            <li>Attenzione</li>
                            <li>Formazione continua</li>
                        </ol>
                    </ul>
                </p>
                <p>
                    È in questo contesto, e avendo ben presente le difficoltà poste dal notevole contenuto tecnologico insito nelle nostre attività, che nasce una profonda convinzione: la qualità si ottiene solo con il totale coinvolgimento e l'attiva partecipazione di tutti e tutte, ad ogni livello della struttura aziendale e indipendentemente dalla funzione.<br /><br />
                    Per questo motivo Divitech da sempre promuove la partecipazione attiva alla vita aziendale di tutti i collaboratori. Il rispetto per le persone (che siano clienti, fornitori, o colleghi), la trasparenza nella gestione dell'azienda e delle singole commesse, l'assenza di burocrazia e non ultimo l'attenzione a eliminare o ridurre i rischi per i dipendenti e per le altre parti interessate, sono la base su cui è programmata l'intera vita aziendale.<br />
                    Divitech unisce un livello di competenze tecnologiche elevato, una solidità economica non scalfita dagli anni della crisi e della pandemia e la tensione continua alla costruzione di un ambiente confortevole e sempre più a misura delle esigenze delle persone.<br /><br />
                    Col tempo abbiamo capito che per fornire i migliori prodotti è fondamentale la qualità del tempo impegnato e non necessariamente la quantità, per questo abbiamo creato un contesto lavorativo caratterizzato da grande flessibilità, massima responsabilizzazione delle persone ed elevato comfort degli ambienti, realizzati per coniugare i momenti di lavoro e quelli ricreativi, riservando un'attenzione particolare alla sicurezza e al benessere sul luogo di lavoro.<br /><br />
                    In accordo con quella che da sempre è la Politica per la Qualità di Divitech, <strong>Divitech ha aderito nel 2012-2013 al Progetto LOIEs</strong> (progetto europeo sulla Responsabilità Sociale d'Impresa).
                    <br /><br />
                    L'iniziativa, che ha coinvolto parte del management e una selezione qualificata di dipendenti, ha prodotto una rilettura dei <strong>sette principi che regolano la responsabilità sociale</strong> che sono stati declinati e tradotti nel contesto Divitech e integrati nella Politica Aziendale.<br /><br />

                    <ol>
                        <li><strong>RISPETTO DEI DIRITTI UMANI</strong></li>
                        <li><strong>RISPETTO DELLE NORMATIVE INTERNAZIONALI</strong></li>
                        <li><strong>RISPETTO DELLA LEGGE</strong></li>
                        <li><strong>RISPETTO E TUTELA DEGLI STAKEHOLDERS</strong></li>
                        <li><strong>CORRETTEZZA, DEMOCRATICITÀ</strong> e <strong>SENSIBILITÀ</strong> nell'affrontare le tematiche personali dei dipendenti </li>
                        <li><strong>ETICA</strong></li>
                        <li><strong>TRASPARENZA E RESPONSABILITÀ</strong> concretizzate nella necessità di esplicitare e comunicare in Divitech le strategie aziendali così che possano essere pienamente comprese da colleghi e dipendenti </li>
                    </ol>


                </p>
                <p>
                    Infine, in relazione al passaggio alla nuova Norma ISO 9001:2015, il top management aziendale e le evidenze delle ricognizioni fatte negli ultimi anni con il personale, hanno portato a rivalutare il Sistema Integrato di Gestione in un'ottica di risk-based thinking.
                </p>
            </div>

            <div className='footer'>
                <div>
                    <img src={LogoDivitech} className='logoDivitechFooter' />
                </div>

            </div>
        </div>

    );
}

export default QualityPolicy;
