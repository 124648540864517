import * as React from 'react';
import PropTypes from 'prop-types';
import { ReactNode } from 'react';
import { saveAs } from 'file-saver';
import LogoDivitech from '../../asset/loghi/logo_scritta_bianca_trasparente.png'
import {
    Box,
    Container, Heading, Image,
    Link,
    SimpleGrid,
    Stack,
    useColorModeValue,
} from '@chakra-ui/react';
import {
    Link as LinkRouter,
} from "react-router-dom";

const ListHeader = ({ children }) => {
    return (
        <Heading
            textTransform={'uppercase'}
            my={6}
            fontSize={'xl'}
            fontWeight={600}
            color={'white'}
        >
            {children}
        </Heading>
    );
};

function Footer() {

    const FOOTER_ITEMS = [
        {
            title: 'About',
            links: [
                {
                    title: 'La nostra storia',
                    url: '/about',
                },
            ],
        },
        {
            title: 'Servizi',
            links: [
                {
                    title: 'Sicurezza',
                    url: '/Security',
                },
                {
                    title: 'Logistica',
                    url: '/logistic',
                },
                {
                    title: 'Smart City',
                    url: '/SmartCity',
                },
            ],
        },
        {
            title: 'Contatti',
            links: [
                {
                    title: 'Scrivici',
                    url: '/Form',
                },
                {
                    title: 'Lavora con noi',
                    url: '/WorkWithUs',
                },
            ],
        },
        {
            title: 'Policies',
            links: [
                {
                    title: 'Politiche qualità',
                    url: '/quality-policy',
                },
                {
                    title: 'Privacy Policy',
                    url: '/privacy-policy',
                },
            ],
        },
    ];

    return (
        <Box backgroundColor={'brand.primary'} color={'white'}>
            <Container as={Stack} maxW={'6xl'} py={10}>
                <SimpleGrid
                    templateColumns={{ sm: '1fr 1fr', md: '2fr 1fr 1fr 1fr 1fr' }}
                    spacing={8}>
                    <Stack
                        order={{
                            base: 2,
                            md: 0,
                        }}
                        spacing={4}
                        justifyContent={'flex-end'}
                    >
                        <Box>
                            <Image
                                maxWidth={'75%'}
                                alt={'Logo Divitech'}
                                src={LogoDivitech}
                                position={'relative'}
                                top={{ sm: '0px', md: '-65px' }}
                            />
                        </Box>
                    </Stack>
                    {
                        FOOTER_ITEMS.map((item, index) => (
                            <Stack key={index} align={'flex-start'}>
                                <ListHeader>{item.title}</ListHeader>
                                {
                                    item.links.map((link, index2) => (
                                        <Link
                                            as={link.onClick ? 'a' : LinkRouter}
                                            key={index2}
                                            to={link.url}
                                            onClick={link.onClick}
                                        >
                                            {link.title}
                                        </Link>
                                    ))
                                }
                            </Stack>
                        ))
                    }
                </SimpleGrid>
            </Container>
        </Box>
    );
}

Footer.propTypes = {};

Footer.defaultProps = {};

export default Footer;
